@charset "UTF-8";
/*! extras
*/
/*
* @Author: Jeff Shields
* @Date:   2019-01-20 07:34:24
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2021-08-23 17:27:52
*/
/*! bs4 required
*/
/*
* @Author: Jeff Shields
* @Date:   2019-01-20 06:51:37
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2020-08-01 12:05:00
*/
/*
* @Author: Jeff Shields
* @Date:   2020-01-25 07:08:34
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2021-08-23 11:28:35
*/
/*
* @Author: Jeff Shields
* @Date:   2020-01-25 07:08:10
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2021-08-13 07:16:50
*/
/* load extras
* @Author: Jeff Shields
* @Date:   2019-01-20 07:22:35
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2020-01-25 11:54:05
*/
/*
* @Author: Jeff Shields
* @Date:   2020-01-25 06:55:12
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2020-01-25 11:50:48
*/
/*
    Custom styles

*/
@media (min-width: 1200px) {
  html {
    font-size: 1.25rem;
  }

  .navbar * {
    font-size: 0.8rem;
  }
}
.navbar-brand {
  max-width: 75%;
}

.rhythum {
  margin-bottom: 1.5rem !important;
}

.picture-frame {
  border: 5px ridge #ffc107;
}

.img-info1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.img-info1:before,
.img-info:after {
  content: "";
}

.blockquote-quotemark {
  padding-left: 2em;
  border-left: 0 solid var(--primary);
}
.blockquote-quotemark:before {
  display: block;
  height: 0;
  content: "“";
  margin-left: -0.5em;
  font: italic 4em/0.75 Cochin, Georgia, "Times New Roman", serif;
  color: var(--info);
}

.blockquote-color {
  border-color: var(--primary);
}

.blockquote-color-bg {
  border-color: var(--secondary);
  border-bottom: solid 1px var(--primary);
  background-color: var(--secondary);
}

.blockquote-big {
  font-size: 1.8em;
  line-height: 140%;
  font-style: oblique;
  border: none;
}

.blockquote-big footer {
  font-style: normal;
  font-size: 0.7em;
}

.blockquote-color-bg-primary {
  font-size: 1em;
  background-color: var(--primary);
  color: #fff;
  padding: 20px;
  text-align: center;
  position: relative;
  border: none;
}

.blockquote-color-bg-primary:after,
.blockquote-color-bg-dark:after {
  font-family: "FontAwesome";
  content: "\f10e";
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: #fff;
  font-size: 2.6em;
}

.blockquote-color-bg-dark {
  font-size: 1em;
  background-color: var(--dark);
  color: var(--light);
  padding: 20px;
  text-align: center;
  position: relative;
  border: none;
}

.blockquote-color-bg-light {
  background-color: var(--light);
  border: none;
  color: var(--dark);
  font-size: 1em;
  padding: 20px;
  position: relative;
  text-align: center;
  text-shadow: 1px 1px 0 white;
  text-shadow: 1px 1px 0 white;
}

.blockquote-color-bg-light:after {
  font-family: "FontAwesome";
  content: "\f10e";
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: var(--dark);
  font-size: 2.6em;
}

.blockquote-color-bg-primary footer,
.blockquote-color-bg-dark footer {
  color: var(--light);
  font-size: 0.9em;
}

.blockquote-color-bg-light footer {
  color: var(--dark);
  font-size: 0.9em;
}

.browsehappy {
  margin: 0.2em 0;
  background: var(--light);
  color: var(--dark);
  padding: 60px 1em 0.2em;
  text-align: center;
}

.day-of-week {
  width: 14.2857142857%;
}

/*
* @Author: Jeff Shields
* @Date:   2019-01-16 18:44:24
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2021-08-23 12:58:35
*/
@media (min-width: 992px) {
  .card-columns {
    column-count: 3;
  }
}
@media (min-width: 1200px) {
  .card-columns {
    column-count: 4;
  }
}

@media (min-width: 992px) {
  .card-columns-3 {
    column-count: 3;
  }
}

@media (min-width: 0) {
  .card-deck .card {
    flex: 0 0 calc(100% - 30px);
  }
}
@media (min-width: 576px) {
  .card-deck .card {
    flex: 0 0 calc(50% - 30px);
  }
}
@media (min-width: 768px) {
  .card-deck .card {
    flex: 0 0 calc(50% - 30px);
  }
}
@media (min-width: 992px) {
  .card-deck .card {
    flex: 0 0 calc(33.3333333333% - 30px);
  }
}
@media (min-width: 1200px) {
  .card-deck .card {
    flex: 0 0 calc(25% - 30px);
  }
}
/*
* @Author: Jeff Shields
* @Date:   2018-03-27 15:33:23
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-03-27 11:52:34
*/
.google-maps {
  width: 98%;
  height: 20rem;
  margin: 0 auto 1rem;
}

.google-maps--large {
  height: 25rem;
}

.google-map iframe {
  height: 100%;
  width: 100%;
}

/*
* @Author: Jeff Shields
* @Date:   2019-03-31 07:40:09
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2021-08-23 12:46:57
*/
.overlay {
  background-color: #000;
  opacity: 1;
}

.overlay:hover {
  background-color: var(--light);
  opacity: 0.5;
  transition: opacity 0.3s;
}

.image-container {
  position: relative;
  background-color: var(--dark);
}

.image-overlay {
  position: absolute;
  left: 0;
  top: 2px;
  opacity: 1;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 1029;
}

.island, .island-left {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .island {
    max-width: 33%;
    margin-left: 1em;
    float: right;
  }

  .island-left {
    max-width: 33%;
    margin-right: 1em;
    float: left;
  }

  .island-sm {
    max-width: 25%;
  }

  .island-lg {
    max-width: 50%;
  }
}
/*
* @Author: Jeff Shields
* @Date:   2019-01-29 09:33:13
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2021-08-23 12:47:49
*/
.separator .list-inline-item + .list-inline-item {
  border-left: 1px solid currentColor;
  padding-left: 0.5rem;
}

.list-checkmarks {
  padding-left: 1.5rem;
  list-style: none;
}
.list-checkmarks li {
  padding-left: 1rem;
}
.list-checkmarks li:before {
  content: "✓";
  margin: 0 1em 0 -2em;
}

.list-checkmarks-success li::before {
  color: var(--success);
}

.list-checkmarks-warning li:before {
  color: var(--warning);
}

.list-checkmarks-danger li:before {
  color: var(--danger);
}

.list-checkmarks-info li:before {
  color: var(--info);
}

.smallcaps {
  font-variant: small-caps;
}

.dropcap::first-letter {
  background-color: transparent;
  color: var(--primary);
  float: left;
  font: normal normal 3.5em/0.8em serif;
  margin: 0 0.083em 0 0;
  padding: 0.0625em 0 0;
  text-shadow: 3px, 2px, 2px, rgba(0, 0, 0, 0.5);
}

.dropcaps::first-letter {
  float: left;
  color: var(--primary);
  font-size: 3em;
  font-weight: 400;
  padding: 10px 10px 10px 5px;
  margin-top: 2px;
}

.dropcaps-bg::first-letter {
  background-color: var(--primary);
  color: var(--white);
  padding-bottom: 12px;
  margin-right: 10px;
  border-radius: 2px;
  padding: 10px 5px;
  font-size: 2.8em;
  margin-top: 4px;
}

.dropcaps-circle::first-letter {
  border: solid 1px var(--primary);
  border-radius: 30px;
  padding: 10px 8px;
  font-size: 2.3em;
  margin-right: 10px;
  margin-top: 2px;
}

.dropcaps-circle-bg::first-letter {
  background-color: var(--primary);
  color: var(--white);
  border-radius: 30px;
  padding: 10px 8px;
  font-size: 2.3em;
  margin-right: 10px;
  margin-top: 2px;
}

/*
* @Author: Jeff Shields
* @Date:   2019-03-31 07:42:46
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2020-01-25 11:41:35
*/
.loading-rn {
  display: none;
}

#embeded iframe,
.embeded iframe {
  width: 100%;
}

/*--------------------------------------------------*/
/* Back to Top */
/*--------------------------------------------------*/
.back-top a {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999999999;
  color: #eee;
  background-color: #050505;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.25s ease;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.back-top a:hover {
  background-color: #050505;
  background-color: rgba(0, 0, 0, 0.7);
}

.back-top a i {
  font-size: 32px;
}

@media (max-width: 991.98px) {
  .back-top a {
    position: fixed;
    bottom: 40px;
    right: 10px;
  }

  .back-top a i {
    font-size: 1.6em;
  }
}
/*
* @Author: Jeff Shields
* @Date:   2019-03-27 10:49:09
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2021-08-23 12:49:21
*/
.nav-logo {
  height: 2.6em;
  margin-left: 1em;
}
@media (min-width: 768px) {
  .nav-logo {
    height: 3em;
    margin-left: 3em;
  }
}
@media (min-width: 992px) {
  .nav-logo {
    height: 3.5em;
    margin-left: 4em;
  }
}

.mbr-section-btn a.btn:not(.btn-form) {
  border-radius: 100px;
}

.mobi .btn {
  padding: 0.4rem 1.5rem;
  display: -webkit-inline-flex;
  align-items: center;
  -webkit-align-items: center;
}

.btn-white-outline {
  background: none;
  border-color: var(--white);
  color: var(--dark);
}

.btn-white-outline:active,
.btn-white-outline.active {
  background: var(--white);
  border-color: var(--white);
  color: var(--dark);
}

/*
* @Author: Jeff Shields
* @Date:   2019-01-29 10:24:25
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2021-08-23 13:00:58
*/
.box {
  position: relative;
  border: 0 solid var(--dark);
  background: transparent;
}

/* common */
.ribbon {
  width: 180px;
  height: 180px;
  overflow: hidden;
  position: absolute;
  top: -5px;
  left: -5px;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 3px solid var(--success);
}

.ribbon span {
  position: absolute;
  display: block;
  width: 300px;
  padding: 10px 0;
  box-shadow: 0 3px 10px -5px black;
  color: #fff;
  font: 700 16px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  right: -46px;
  top: 59px;
  transform: rotate(-45deg);
}

.ribbon::before {
  top: 0;
  right: 0;
}

.ribbon::after {
  bottom: 0;
  left: 0;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid var(--success);
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--success);
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid var(--success);
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--success);
}

.ribbon.ribbon-success span {
  background: var(--success);
  background: linear-gradient(#1e7e34 0%, var(--success) 100%);
}

.ribbon.ribbon-success span::before {
  border-left: 3px solid var(--success);
  border-top: 3px solid var(--success);
}

.ribbon.ribbon-success span::after {
  border-right: 3px solid var(--success);
  border-top: 3px solid var(--success);
}

.ribbon.ribbon-danger::before,
.ribbon.ribbon-danger::after {
  border: 3px solid var(--danger);
}

.ribbon.ribbon-danger span {
  background: var(--danger);
  background: linear-gradient(#bd2130 0%, var(--danger) 100%);
}

.ribbon.ribbon-danger span::before {
  border-left: 3px solid var(--danger);
  border-top: 3px solid var(--danger);
}

.ribbon.ribbon-danger span::after {
  border-right: 3px solid var(--danger);
  border-top: 3px solid var(--danger);
}

/*! _bootnavbar
*/
/*
* @Author: Jeff Shields
* @Date:   2020-01-02 13:30:02
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2021-08-31 10:57:16
*
* @source: https://github.com/kmlpandey77/bootnavbar
*/
.dropdown-menu {
  margin-top: 0;
}

.dropdown-menu .dropdown-toggle::after {
  vertical-align: middle;
  border-left: 4px solid;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
}

.dropdown-menu .dropdown .dropdown-menu {
  left: 95%;
  top: 0%;
  margin: 0 20px;
  border-width: 0;
}

.dropdown-menu .dropdown .dropdown-menu.left {
  right: 95%;
  left: auto;
}

.dropdown-menu > .dropdown-item a:hover,
.dropdown-menu > .dropdown-item.show {
  background: #007bff;
  color: white;
}

.dropdown-menu > .dropdown-item.show > a {
  color: white;
}

@media (min-width: 768px) {
  .dropdown-menu .dropdown .dropdown-menu {
    margin: 0;
    border-width: 1px;
    max-height: 50vh;
    overflow: auto;
  }
}
.nav-item.dropdown.active a.dropdown-item.child[data-depth="2"] {
  padding-left: 3em;
}

@media print {
  body {
    font: 12pt Georgia, "Times New Roman", Times, serif;
    line-height: 1.3;
    background: #fff !important;
    color: #000;
  }

  h1, .h1 {
    font-size: 19.5pt;
  }

  h2, .h2 {
    font-size: 18pt;
  }

  h3, .h3 {
    font-size: 16.5pt;
  }

  h4, .h4 {
    font-size: 15pt;
  }

  h5, .h5 {
    font-size: 13.5pt;
  }

  h6, .h6 {
    font-size: 12pt;
  }

  .lead {
    font-size: 13.5pt;
  }

  #demoSelect,
#back-top {
    display: none;
  }

  .container {
    width: auto;
  }

  .card-columns {
    column-count: 2;
  }
}

/*# sourceMappingURL=extras.css.map */
